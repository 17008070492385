import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageAbout = props => (
  <Layout {...props}>
    <Seo title='About' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Learn About Us"
          subheader='The origin story for Tech Square is an inspiring example of how taking a risk and thinking outside the box can pay be rewarding. By embracing a non-hierarchical organizational structure, they created a workplace where everyone felt valued and heard. That same spirit of inclusivity and collaboration that has made Tech Square such a success.'
          running={`But it wasnt always easy. There were many late nights and early mornings, countless setbacks and challenges. But Ryan and Chris refused to give up. They believed in their vision and were willing to do whatever it took to make it a reality. 

          Their hard work and perseverance paid off. Tech Square quickly became a go-to destination for tech enthusiasts, and it continues to grow and evolve to this day. Ryan and Chris have since expanded their team, and they continue to prioritize a democratic organizational structure. They believe that its the key to their success. 

          At Tech Square, everyone has a say. Whether you are a writer, editor, or developer, your voice matters. Ryan and Chris have created a workplace where ideas are shared and discussed, and decisions are made collaboratively. It is a refreshing departure from the traditional corporate model, and it is one thats paying off. 

          If you are a tech enthusiast looking for a community where you can connect and learn, Tech Square is the place for you. And if you are a job seeker looking for a workplace that values inclusivity and collaboration, Tech Square might just be your dream job. 
          
          In a world where uncertainty and change are the new norm, the origin story for Tech Square is a reminder that anything is possible with hard work, determination, and a willingness to take risks. They refused to let the pandemic defeat them, and instead, they used it as an opportunity to create something new and meaningful.`}
        />
        <Divider />
      </Main>
      <Sidebar>
        <Divider />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageAbout
